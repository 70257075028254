<!-- eslint-disable max-len -->

<template>
  <div v-if="role.isC">
    <div class="pt-5"></div>
    <div class="container-fluid mt-5">
      <count-down-timer v-if="showCountdown" :weddingDate="weddingDate" class="countdown d-none d-md-block"/>
      <div class="row px-xl-3">
        <div class="col-md-2 col-lg-2" style="background-color: #fff;">
          <div style="top: 120px;" class="sticky-top mb-m5">
            <div class="sidebar-block" id="couple-accordion" role="tablist">
              <div @click="showCoupleSideNav" class="block-header d-flex justify-content-between align-items-center shadow-sm" style="cursor: pointer;">
                <strong><a :href="`#${hash}`" class="accordion-link">Start</a></strong>
                <i class="fas text-right" :class="showCoupleMenu ? 'fa-angle-up' : 'fa-angle-down'"></i>
              </div>
              <transition name="toggle">
                <div v-if="showCoupleMenu" class="collapse shadow-sm rounded-iwh show" @click="scrollUp">
                  <nav class="nav nav-pills flex-column mb-0 pb-0">
                    <div v-for="(c,i) in comps" :key="i">
                      <!-- <a @click="startMenu(c.name)" :href="`${c.tab.toLowerCase()}`" class="nav-link" :class="couplePath==`couple-${c.tab.toLowerCase()}`? 'active':''"> {{c.tab}} <span v-if="c.new" class="badge badge-warning ml-1">New</span></a> -->
                      <!-- <router-link :to="{name: `couple-${c.tab.toLowerCase()}`}" class="nav-link"> {{c.tab}} <span v-if="c.new" class="badge badge-warning ml-1">New</span></router-link> -->
                      <router-link :to="{name: `couple-${c.tab.toLowerCase()}`}" class="nav-link"> {{c.tab}} <span v-if="c.new" class="badge badge-warning ml-1">New</span></router-link>
                      <div v-if="i===1 || i===4" class="dropdown-divider my-1"></div>
                    </div>
                  </nav>
                </div>
              </transition>
            </div>
          </div>
        </div>
        <div class="col tab-content">
          <!-- <keep-alive>
            <component v-bind:is="comp" />
          </keep-alive> -->
          <!-- <router-view name="couple"></router-view> -->
          <router-view name="couple" v-slot="{Component}">
            <transition name="fade">
              <component :is="Component"/>
            </transition>
          </router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { defineAsyncComponent } from 'vue';
// import LaunchWedding from '../../components/Couples/LaunchWedding.vue';
// import BookVendors from '../../components/Couples/BookVendors.vue';
// import ContributionsReceived from '../../components/Couples/ContributionsReceived.vue';
// // import GuestList from '../../components/Couples/GuestList.vue';
// // import WishList from '../../components/Couples/WishList.vue';
// import CoupleProfile from '../../components/Couples/CoupleProfile.vue';
// import EWallet from '../../components/Couples/E-Wallet.vue';
// // import UpcomingWeddings from '../../components/Couples/UpcomingWeddings.vue';
// import PaymentsMade from '../../components/Couples/PaymentsMade.vue';
// import CountDownTimer from '../../components/CountDownTimer.vue';
const CountDownTimer = defineAsyncComponent(() => import('../../components/CountDownTimer.vue'));

export default {
  name: 'couple-account',
  components: {
    // LaunchWedding,
    // BookVendors,
    // ContributionsReceived,
    // // GuestList,
    // // WishList,
    // CoupleProfile,
    // EWallet,
    // // UpcomingWeddings,
    // PaymentsMade,
    CountDownTimer,
  },
  data: () => ({
    comp: 'CoupleProfile',
    hash: 'profile',
    comps: [
      { name: 'CoupleProfile', tab: 'Profile', new: false },
      { name: 'EWallet', tab: 'Wallet', new: false },
      { name: 'LaunchWedding', tab: 'Launch', new: false },
      { name: 'BookVendors', tab: 'Budget', new: false },
      { name: 'ContributionsReceived', tab: 'Contributions', new: true },
      // { name: 'GuestList', tab: 'Guests', new: false },
      // { name: 'WishList', tab: 'Wishlist', new: false },
      { name: 'PaymentsMade', tab: 'Transactions', new: false },
      // { name: 'UpcomingWeddings', tab: 'Upcoming', new: false },
    ],
    showCoupleMenu: true,
    showCountdown: true,
  }),
  created() {
    this.$store.dispatch('setUserRole');
    // console.log('created', this.role, this.isAuth);
    // console.log('token', this.token, 'user', this.user);
    // this.$store.dispatch('setCurrentUser');
  },
  methods: {
    showCoupleSideNav() {
      this.showCoupleMenu = !this.showCoupleMenu;
    },
    scrollUp() {
      // this.$store.dispatch('smoothScroll');
      // if (window.screen.width < 992) {
      //   window.scroll({
      //     top: 0,
      //     left: 0,
      //     behavior: 'smooth',
      //   });
      // } else {
      //   setTimeout(() => {
      //     // window.scrollTo(0, 100);
      //     window.scroll({
      //       top: 100,
      //       left: 0,
      //       behavior: 'smooth',
      //     });
      //   }, 100);
      // }
      this.startMenu();
    },
    startMenu() {
      if (window.screen.width < 768) {
        setTimeout(() => {
          this.showCoupleMenu = false;
        }, 100);
      }
      // this.showCoupleMenu = false;
    },
  },
  computed: {
    // ...mapState(['role']),
    ...mapGetters(['wedding_date', 'token', 'user', 'role', 'isAuth']),
    weddingDate() {
      return this.wedding_date;
    },
    couplePath() {
      return this.$route.name;
    },
  },
};
</script>

<style scoped>
.countdown{
  margin-top: 5rem;
}
.router-link-active, .router-link-exact-active {
  background-color: #e9ecef;
}
.fade-enter-active, .fade-leave-active {
  transition: all 0s ease-in-out;
}
.fade-enter-from, .fade-leave-to {
  /* transform: translateY(-3px); */
  opacity: 0;
}
.toggle-enter-active, .toggle-leave-active {
  transition: all 0s ease-in-out;
}
.toggle-enter-from, .toggle-leave-to {
  transform: translateY(-12px);
  opacity: 0;
}
</style>
